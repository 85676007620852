export const EXP_COMPACT_LAYOUT = 'exp_shop_login_new_ui:variant_modal_compact';

/**
 * Identify if given experiment is compact layout
 * @param {string|undefined} experimentsAndVariants the experiments and variants string
 * @returns {boolean} whether the experiment is compact layout
 */
function isCompactLayout(experimentsAndVariants?: string): boolean {
  const experimentsAndVariantsArray = experimentsAndVariants?.split(';') ?? [];
  return experimentsAndVariantsArray.includes(EXP_COMPACT_LAYOUT);
}

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/ban-types
type CompactParams = {compact_layout: 'true'} | {};
/**
 * Create object with parameter compact_layout when experiment is compact layout
 * @param {string|undefined} experimentsAndVariants the experiments and variants string
 * @returns {CompactParams} the params for layout compact
 */
export function getParamIsCompact(
  experimentsAndVariants?: string,
): CompactParams {
  return isCompactLayout(experimentsAndVariants)
    ? // eslint-disable-next-line @typescript-eslint/naming-convention
      {compact_layout: 'true'}
    : {};
}
